import { css } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';

export const MULTILINE_BOOK_DESKTOP_WIDTH = 230;
export const MULTILINE_BOOK_DESKTOP_HEIGHT = 334;
export const MULTILINE_BOOK_MOBILE_WIDTH = 185;
export const MULTILINE_BOOK_MOBILE_HEIGHT = 268;

const MULTILINE_BOOK_DESKTOP_METADATA_HEIGHT = 62;
const MULTILINE_BOOK_MOBILE_METADATA_HEIGHT = 48;

export const multilineStyle = css`
  display: flex;
  flex-wrap: wrap;
  margin: -15px -3px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin: -8px -3px;
    `,
  )};
`;

export const multilineItemStyle = css`
  padding: 15px 3px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 8px 4px;
    `,
  )};

  width: calc(100% / 6);
  ${orBelow(
    BreakPoint.GridLarge,
    css`
      width: calc(100% / 5);
    `,
  )};

  ${orBelow(
    BreakPoint.GridMedium,
    css`
      width: calc(100% / 4);
    `,
  )};

  ${orBelow(
    BreakPoint.GridSmall,
    css`
      width: calc(100% / 3);
    `,
  )};
`;

export const multilineBookStyle = css`
  width: 100% !important;
`;

export const multilineBookMetadataStyle = css`
  min-height: ${MULTILINE_BOOK_DESKTOP_METADATA_HEIGHT}px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      min-height: ${MULTILINE_BOOK_MOBILE_METADATA_HEIGHT}px;
    `,
  )};
`;

export const multilineBookFastRendererStyle = css`
  width: 100% !important;
  padding-bottom: calc(
    ${MULTILINE_BOOK_DESKTOP_METADATA_HEIGHT}px +
      ${(MULTILINE_BOOK_DESKTOP_WIDTH / MULTILINE_BOOK_DESKTOP_HEIGHT) * 100}%
  );

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-bottom: calc(
        ${MULTILINE_BOOK_MOBILE_METADATA_HEIGHT}px +
          ${(MULTILINE_BOOK_MOBILE_WIDTH / MULTILINE_BOOK_MOBILE_HEIGHT) * 100}%
      );
    `,
  )};
`;
