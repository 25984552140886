import {
  BookDefinition,
  BookPreset,
  RenewalBookPreset,
  RenewalBookPresetComponents,
  RenewalBookSizePreset,
} from '@/components/common/Book';
import { GenreHome3InfoBasePreset, GenreHomeBookItemRenderer } from '@/components/genreHome/common/GenreHomeBook';
import { SectionLayout } from '@/components/genreHome/common/SectionLayout';
import { useSectionTrackingDataContext } from '@/components/genreHome/common/SectionTrackingDataContextProvider';
import { BreakPoint } from '@/components/styles/media';
import { SectionByLayout } from '@/features/genreHome/views/utils/viewDataUtils';
import { SectionLayoutType } from '@/models/backendsApi/v2/Views/ViewsType';

import * as styles from './SelectionMultiline.styles';
import {
  MULTILINE_BOOK_DESKTOP_HEIGHT,
  MULTILINE_BOOK_DESKTOP_WIDTH,
  MULTILINE_BOOK_MOBILE_HEIGHT,
  MULTILINE_BOOK_MOBILE_WIDTH,
} from './SelectionMultiline.styles';

const SelectionMultilineBasePreset: BookPreset = components => {
  const presetComponents = { ...components } as RenewalBookPresetComponents;
  presetComponents.BookFastRenderer = presetComponents.BookFastRenderer.addStyle(styles.multilineBookFastRendererStyle);
  presetComponents.BookRenderer = presetComponents.BookRenderer.addStyle(styles.multilineBookStyle);
  presetComponents.BookMetadataRenderer = presetComponents.BookMetadataRenderer.addStyle(
    styles.multilineBookMetadataStyle,
  );

  return presetComponents;
};

const SelectionMultilinePreset = [
  RenewalBookPreset,
  RenewalBookSizePreset([
    {
      greaterThan: BreakPoint.DesktopSmall,
      value: { width: MULTILINE_BOOK_DESKTOP_WIDTH, height: MULTILINE_BOOK_DESKTOP_HEIGHT, type: 700 },
    },
    {
      orBelow: BreakPoint.DesktopSmall,
      value: { width: MULTILINE_BOOK_MOBILE_WIDTH, height: MULTILINE_BOOK_MOBILE_HEIGHT, type: 400 },
    },
  ]),
  ...GenreHome3InfoBasePreset,
  SelectionMultilineBasePreset,
];

export interface SelectionMultilineProps {
  section: SectionByLayout<SectionLayoutType.SelectionMultiline>;
}

export const SelectionMultiline = ({ section }: SelectionMultilineProps): ReactJSX.Element => {
  const sectionTrackingData = useSectionTrackingDataContext();

  return (
    <BookDefinition presets={SelectionMultilinePreset} trackingData={sectionTrackingData.bookDefinition}>
      <SectionLayout
        horizontal={false}
        title={section.title}
        link={section.more_details?.path}
        paddingBottom={false}
        threshold={0}>
        <ul css={styles.multilineStyle}>
          {section.items.map((item, index) => (
            <li css={styles.multilineItemStyle} key={item.contents.book.bookId}>
              <GenreHomeBookItemRenderer item={item} index={index} />
            </li>
          ))}
        </ul>
      </SectionLayout>
    </BookDefinition>
  );
};
